<template>
  <div>
    <TextAreaDialog
      v-if="showInformationDialog"
      :show-dialog="showInformationDialog"
      :title="value.title"
      :subTitle="value.disclaimer"
      :text-value="value.description"
      :disabled="true"
      @close="closeInformationDialog"
    />
    <DashConfigDialog
      v-if="editDialog"
      v-model="editDialog"
      :dash="value"
      @updated="updated"
      @deleted="deleted"
    />
    <v-hover v-slot:default="{ hover }">
      <v-data-table
        v-if="content === null"
        :loading="true"
        hide-default-footer
      />
      <v-card
        v-else
        outlined
        rounded
      >
        <v-toolbar
          class="pt-2"
          extension-height="16px"
          height="24px"
          flat
          dense
          extended
        >
          <v-toolbar-title class="grey--text text--darken-2 text-subtitle-2">
            {{ value.title }}
          </v-toolbar-title>

          <v-spacer />

          <template v-if="$vuetify.breakpoint.smAndUp && hover">
            <v-btn
              :disabled="processing"
              :loading="processing"
              small
              text
              @click="exportToPDF()"
            >
              {{ $t('pdf') }}
            </v-btn>
            <v-btn
              :disabled="processing"
              :loading="processing"
              small
              text
              @click="exportToXLS()"
            >
              {{ $t('xls') }}
            </v-btn>
            <v-btn
              v-if="
                value !== null &&
                value !== undefined &&
                content !== null &&
                content !== undefined &&
                content.alerts !== null &&
                content.alerts !== undefined &&
                Array.isArray(content.alerts) &&
                content.alerts.length > 0
              "
              @click="showAlerts"
              text
              class="warning--text"
              small
              style="text-align: left"
            >
              <div style="width: 100%">
                <small
                  >{{ content.alerts.length }}
                  <v-icon
                    color="warning"
                    x-small
                    class="pr-1"
                    >mdi-alert-outline</v-icon
                  ></small
                >
              </div>
            </v-btn>
            <v-btn
              v-if="
                value !== null &&
                value !== undefined &&
                value.alerts !== null &&
                value.alerts !== undefined &&
                Array.isArray(value.alerts) &&
                value.alerts.length > 0
              "
              @click="showSingleValueAlerts"
              text
              class="warning--text"
              small
              style="text-align: left"
            >
              <div style="width: 100%">
                <small
                  >{{ value.alerts.length }}
                  <v-icon
                    color="warning"
                    x-small
                    class="pr-1"
                    >mdi-alert-outline</v-icon
                  ></small
                >
              </div>
            </v-btn>
            <v-btn
              :disabled="processing || first"
              small
              icon
              @click="decreaseOrder"
            >
              <v-icon
                small
                dense
              >
                mdi-arrow-up-bold
              </v-icon>
            </v-btn>
            <v-btn
              :disabled="processing || last"
              small
              icon
              @click="increaseOrder"
            >
              <v-icon
                small
                dense
              >
                mdi-arrow-down-bold
              </v-icon>
            </v-btn>
            <v-btn
              class="draggable-item"
              small
              icon
              @click="drag()"
            >
              <v-icon
                small
                dense
              >
                mdi-cursor-move
              </v-icon>
            </v-btn>
            <v-btn
              :disabled="processing"
              small
              icon
              @click="edit()"
            >
              <v-icon
                small
                dense
              >
                mdi-settings
              </v-icon>
            </v-btn>
            <v-btn
              :disabled="processing"
              :loading="processing"
              small
              icon
              @click="refresh()"
            >
              <v-icon
                small
                dense
              >
                mdi-refresh
              </v-icon>
            </v-btn>
            <v-btn
              v-if="value.description !== null && value.description !== undefined && value.description !== ''"
              :disabled="processing"
              :loading="processing"
              small
              icon
              @click="openInformationDialog()"
            >
              <v-icon
                small
                dense
              >
                mdi-information-outline
              </v-icon>
            </v-btn>
          </template>
          <v-tooltip
            top
            v-if="hasAdditionalSettings()"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                icon
                depressed
                color="grey darken-1"
                v-bind="attrs"
                v-on="on"
              >
                mdi-information-variant
              </v-icon>
            </template>
            <span>This dash has additional settings. Select the cogwheel icon for further information.</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="dashType.supportsLive"
                small
                icon
                depressed
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small> mdi-antenna </v-icon>
              </v-btn>
            </template>
            <span>This dash subscribes to live data changes and will update in real-time.</span>
          </v-tooltip>
          <template #extension>
            <div
              class="text-caption grey--text text--darken-2"
              v-if="value.disclaimer && value.disclaimer !== ''"
            >
              {{ value.disclaimer }}
            </div>
          </template>
        </v-toolbar>

        <div v-if="content !== null">
          <div
            v-if="uiFields && uiFields.length > 0"
            class="ui-fields px-3"
          >
            <v-row v-if="dashType.namespace === value.namespace">
              <v-col
                :cols="uiField.cols"
                :key="uiField.key"
                v-for="uiField in value.uiFields"
              >
                <v-autocomplete
                  v-if="uiField.type === 'select'"
                  outlined
                  dense
                  chips
                  deletable-chips
                  small-chips
                  :label="uiField.label"
                  :items="uiField.options"
                  item-value="key"
                  item-text="label"
                  hide-details
                  multiple
                  v-model="uiValues[uiField.key]"
                  @change="uiFieldChanged(value, uiField)"
                />
              </v-col>
            </v-row>
          </div>
          <v-data-table
            flat
            tile
            :ref="value.uuid"
            @click:row="openDialog"
            :items-per-page="-1"
            :headers="content.headers"
            :items="content.rows"
            :caption="content.caption"
            :loading="value.loading"
            :search="content.search"
            :no-data-text="content['no-data-text']"
            :hide-default-footer="content['hide-default-footer']"
            :group-by="content['group-by']"
            :sort-by="content['sort-by'] ? content['sort-by'] : 'quantity'"
            :sort-desc="content['sort-desc'] ? content['sort-desc'] : true"
            class="elevation-0 no-margin-table"
          />
        </div>
      </v-card>
    </v-hover>
  </div>
</template>

<script>
import axios from 'axios'
import jsesc from 'jsesc'

import TextAreaDialog from '@/components/common/TextAreaDialog'

export default {
  name: 'Dash',
  props: {
    collection: {
      type: Object,
      default: () => {}
    },
    value: {
      type: Object,
      default: () => {}
    },
    first: {
      type: Boolean
    },
    last: {
      type: Boolean
    }
  },
  components: {
    TextAreaDialog,
    DashConfigDialog: () => import('@/components/dash/DashConfigDialog')
  },
  data() {
    return {
      subscription: null,
      googleChartsLib: null,
      editDialog: false,
      lastUpdated: null,
      processing: false,
      content: null,
      cancelToken: null,
      showInformationDialog: false,
      uiValues: {
        startDate: 0,
        endDate: 0,
        locationVariations: []
      }
    }
  },
  mounted() {
    this.fetchContent()
  },
  computed: {
    uiFields() {
      return this.value.uiFields
    },
    dashTypes() {
      return this.$store.state.dashTypes
    },
    dashType() {
      if (Array.isArray(this.dashTypes)) {
        return this.dashTypes.find((dashType) => dashType.namespace === this.value.namespace)
      } else {
        return []
      }
    },
    channels() {
      return this.collection.channels === null || this.collection.channels === undefined ? [] : this.collection.channels
    },
    startTimeInMillis() {
      let startTimeInMillis = 0
      const date = new Date()

      switch (this.collection.period) {
        case 'today':
          date.setUTCHours(0)
          date.setUTCMinutes(0)
          date.setUTCMinutes(0)
          date.setUTCMilliseconds(0)
          startTimeInMillis = date.getTime()
          break
        case 'yesterday':
          date.setUTCDate(date.getUTCDate() - 1)
          date.setUTCHours(0)
          date.setUTCMinutes(0)
          date.setUTCSeconds(0)
          date.setUTCMilliseconds(0)
          startTimeInMillis = date.getTime()
          break
        case 'current_week':
          date.setUTCDate(1)
          startTimeInMillis = this.$moment().startOf('isoweek').valueOf()
          break
        case 'previous_month':
          date.setUTCMonth(date.getUTCMonth() - 1)
          startTimeInMillis = this.$moment().subtract(1, 'months').startOf('month').valueOf()
          break
        case 'previous_week':
          date.setUTCDate(2)
          startTimeInMillis = this.$moment().subtract(1, 'week').startOf('isoweek').valueOf()
          break
        case 'custom':
          startTimeInMillis = this.collection.startTimeInMillis
          break
        default:
          startTimeInMillis = this.$moment().startOf('month').valueOf()
      }

      return startTimeInMillis
    },
    endTimeInMillis() {
      let endTimeInMillis = 0
      const date = new Date()
      switch (this.collection.period) {
        case 'today':
          date.setUTCHours(23)
          date.setUTCMinutes(59)
          date.setUTCMilliseconds(59)
          date.setUTCSeconds(59)
          endTimeInMillis = date.getTime()
          break
        case 'yesterday':
          date.setUTCDate(date.getUTCDate() - 1)
          date.setUTCHours(23)
          date.setUTCMinutes(59)
          date.setUTCSeconds(59)
          date.setUTCMilliseconds(59)
          endTimeInMillis = date.getTime()
          break
        case 'current_week':
          date.setUTCDate(1)
          endTimeInMillis = this.$moment().endOf('isoweek').valueOf()
          break
        case 'previous_month':
          date.setUTCMonth(date.getMonth() - 1)
          endTimeInMillis = this.$moment().subtract(1, 'months').endOf('month').valueOf()
          break
        case 'previous_week':
          date.setUTCDate(2)
          endTimeInMillis = this.$moment().subtract(1, 'week').endOf('isoweek').valueOf()
          break
        case 'custom':
          endTimeInMillis = this.collection.endTimeInMillis
          break
        default:
          endTimeInMillis = this.$moment().endOf('month').valueOf()
      }

      return endTimeInMillis
    },
    timeIntervalFrom() {
      if (!this.collection.timeIntervalFrom) {
        return ''
      }
      return this.collection.timeIntervalFrom
    },
    timeIntervalTo() {
      if (!this.collection.timeIntervalTo) {
        return ''
      }
      return this.collection.timeIntervalTo
    },
    graphUrl() {
      return this.$store.state.graphUrl
    },
    subscriptionBody() {
      const escapedUIValues = jsesc(JSON.stringify(this.uiValues), {
        json: true
      })

      return {
        query:
          'subscription {\n' +
          '    dash(uuid: "' +
          this.value.uuid +
          '", startTimeInMillis: ' +
          this.startTimeInMillis +
          ', endTimeInMillis: ' +
          this.endTimeInMillis +
          ', channels: ' +
          JSON.stringify(this.channels) +
          ', uiValues: ' +
          escapedUIValues +
          ', timeIntervalFrom: "' +
          this.timeIntervalFrom +
          '", timeIntervalTo: "' +
          this.timeIntervalTo +
          '") {\n' +
          '       type\n' +
          '       content\n' +
          '  }\n' +
          '}'
      }
    },
    lastUpdatedAgo() {
      var seconds = Math.floor((new Date() - this.lastUpdated) / 1000)

      var interval = Math.floor(seconds / 31536000)

      if (interval > 1) {
        return interval + ' ' + this.$t('yearsLC')
      }

      interval = Math.floor(seconds / 2592000)
      if (interval > 1) {
        return interval + ' ' + this.$t('monthsLC')
      }

      interval = Math.floor(seconds / 86400)
      if (interval > 1) {
        return interval + ' ' + this.$t('daysLC')
      }

      interval = Math.floor(seconds / 3600)
      if (interval > 1) {
        return interval + ' ' + this.$t('hoursLC')
      }

      interval = Math.floor(seconds / 60)

      if (interval > 1) {
        return interval + ' ' + this.$t('minutesLC')
      }

      if (seconds === 0) {
        return this.$t('justNow')
      }

      return Math.floor(seconds) + ' ' + this.$t('secondsAgo')
    }
  },
  methods: {
    openInformationDialog() {
      this.showInformationDialog = true
    },
    closeInformationDialog() {
      this.showInformationDialog = false
    },
    parseContent(content) {
      this.$worker
        .run(
          (unparsedContent) => {
            return JSON.parse(unparsedContent)
          },
          [content]
        )
        .then((parsedContent) => {
          this.content = parsedContent
        })
    },
    sanitizeContentForExport(sanitizeContentForExport) {
      return this.$worker
        .run(
          (unsanitizedString) => {
            // unsanitizedString = unsanitizedString.replaceAll('.', '')
            // unsanitizedString = unsanitizedString.replaceAll(',', '')
            unsanitizedString = unsanitizedString.replaceAll('&nbsp;kr.', '')
            return unsanitizedString
          },
          [sanitizeContentForExport]
        )
        .then((sanitized) => {
          return sanitized
        })
    },
    openDialog(e) {
      if (e.dialogType && e.dialogComponentSrc && e.dialogPropValue) {
        this.$store.commit(e.dialogType, {
          component: e.dialogComponentSrc,
          props: {
            uuid: e.dialogPropValue
          }
        })
      }
    },
    showAlerts() {
      this.$store.commit('updateGenericDialog', {
        component: 'components/dash/DashAlerts',
        props: {
          alerts: this.content.alerts
        }
      })
    },
    showSingleValueAlerts() {
      this.$store.commit('updateGenericDialog', {
        component: 'components/dash/DashAlerts',
        props: {
          alerts: this.value.alerts
        }
      })
    },
    hasAdditionalSettings() {
      if (
        this.value !== null &&
        typeof this.value !== 'undefined' &&
        this.value.data !== null &&
        typeof this.value.data !== 'undefined' &&
        Object.keys(this.value.data).length > 0
      ) {
        const settings = Object.values(this.value.data)

        for (const i in settings) {
          const setting = settings[i]
          if (setting !== null && typeof setting !== 'undefined' && setting.length > 0) {
            return true
          }
        }
      }
      return false
    },
    uiFieldChanged() {
      this.fetchContent()
    },
    increaseOrder() {
      this.$emit('increaseOrder')
    },
    decreaseOrder() {
      this.$emit('decreaseOrder')
    },
    deleted() {
      this.$store.commit('dashDeleted', this.value)
      this.editDialog = false
    },
    updated(dash) {
      this.$store.commit('dashUpdated', dash)
      this.editDialog = false
    },
    edit() {
      this.editDialog = true
    },
    drag() {},
    exportToPDF() {
      const innerHTML = this.$refs[this.value.uuid].$el.innerHTML
      const fileName =
        this.value.title +
        ' - ' +
        this.$moment(this.startTimeInMillis).toISOString() +
        ' - ' +
        this.$moment(this.endTimeInMillis).toISOString()
      this.$store.commit('exportPDF', {
        fileName: fileName,
        html: innerHTML
      })
    },
    exportToXLS() {
      const innerHTML = this.$refs[this.value.uuid].$el.innerHTML
      const fileName =
        this.value.title +
        ' - ' +
        this.$moment(this.startTimeInMillis).toISOString() +
        ' - ' +
        this.$moment(this.endTimeInMillis).toISOString()
      this.sanitizeContentForExport(innerHTML).then((sanitizedContent) => {
        this.$store.commit('exportStore/exportXLS', {
          fileName: fileName,
          html: sanitizedContent
        })
      })
    },
    refresh() {
      this.fetchContent()
    },
    fetchContent() {
      let body = null
      this.processing = true
      this.content = null

      const escapedUIValues = jsesc(JSON.stringify(this.uiValues), {
        json: true
      })

      if (this.value.uuid !== null && this.value.uuid !== undefined && this.value.uuid !== '00000000-0000-0000-0000-000000000000') {
        body = {
          query:
            'query {\n' +
            '    dash(uuid: "' +
            this.value.uuid +
            '", startTimeInMillis: ' +
            this.startTimeInMillis +
            ', endTimeInMillis: ' +
            this.endTimeInMillis +
            ', channels: ' +
            JSON.stringify(this.channels) +
            ', uiValues: ' +
            escapedUIValues +
            ', timeIntervalFrom: "' +
            this.timeIntervalFrom +
            '", timeIntervalTo: "' +
            this.timeIntervalTo +
            '") {\n' +
            '       type\n' +
            '       content\n' +
            '       disclaimer\n' +
            '       alerts {\n' +
            '         type\n' +
            '         severity\n' +
            '         message\n' +
            '         content\n' +
            '         path\n' +
            '         context\n' +
            '       }\n' +
            '  }\n' +
            '}'
        }
      } else {
        body = {
          query:
            'query {\n' +
            '    dashByNamespace(namespace: "' +
            this.value.namespace +
            '", startTimeInMillis: ' +
            this.startTimeInMillis +
            ', endTimeInMillis: ' +
            this.endTimeInMillis +
            ', channels: ' +
            JSON.stringify(this.channels) +
            ', uiValues: ' +
            escapedUIValues +
            ', timeIntervalFrom: "' +
            this.timeIntervalFrom +
            '", timeIntervalTo: "' +
            this.timeIntervalTo +
            '") {\n' +
            '       type\n' +
            '       content\n' +
            '       disclaimer\n' +
            '  }\n' +
            '}'
        }
      }

      if (this.cancelToken !== null) {
        try {
          this.cancelToken.cancel()
        } catch (e) {
          // Do nothing
        }
      }

      this.cancelToken = null
      this.cancelToken = axios.CancelToken.source()

      axios
        .post(this.graphUrl, body, {
          cancelToken: this.cancelToken.token,
          params: {
            time: new Date().getTime()
          }
        })
        .then((response) => {
          if (response.data.data.dash) {
            try {
              this.parseContent(response.data.data.dash.content)
            } catch (e) {
              // Do nothing
              console.log(response.data.data.dash.content)
              console.log(e)
            }
          } else if (response.data.data.dashByNamespace) {
            this.parseContent(response.data.data.dashByNamespace.content)
          } else {
            // Do nothing
          }
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            // Silently handle cancel error
            return
          }
          console.log(error)
          Sentry.captureException(error)
        })
        .finally(() => {
          this.processing = false
          this.lastUpdated = new Date()
        })
    }
  },
  watch: {
    uiValues() {
      this.fetchContent()
    },
    startTimeInMillis(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.fetchContent()
      }
    },
    endTimeInMillis(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.fetchContent()
      }
    },
    channels(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.fetchContent()
      }
    }
  }
}
</script>

<style>
.no-margin-table table {
  margin-bottom: 0;
}
</style>
